const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

function intAddUniverse({ id, title, description }) {
  return {
    type: 'tag',
    tagId: id,
    i18n: {
      fr: {
        title,
        description,
      },
    },
    heading: {
      canRadio: false,
      color: 'black',
      dark: true,
    },
  }
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */

    siteId: '5c9cac536584013bb7628c91',
    authMode: ['local'],
    authLocalOptions: {
      mode: 'otp',
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    useLTS: true,
    metadatas: {
      name: 'Mediameeting Voice Cases',
      colorPrimary: '#e84b23',
      supportEmailAddress: 'support@bubblecast.co',
    },
    analytics: {
      matomo: '29',
    },
    nuxt: {
      head: {
        title: 'Mediameeting Voice Cases',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Retrouvez vos podcasts sur Voice Cases`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: 'Mediameeting',
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Mediameeting Voice Cases',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Retrouvez vos podcasts sur Voice Cases`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '5f5f6c3924c283e3342b92dd',
            i18n: {
              fr: {
                title: 'Émissions',
              },
            },
            heading: {
              color: '#ff6d00',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '5f5f6c7324c28350c82b92e1',
            i18n: {
              fr: {
                title: 'Podcasts narratifs',
              },
            },
            heading: {
              color: '#fb8c00',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '5f5f6c5324c28366662b92df',
            i18n: {
              fr: {
                title: 'Podcasts immersifs',
              },
            },
            heading: {
              color: '#ffa726',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '5f5f6c6224c2839c5e2b92e0',
            i18n: {
              fr: {
                title: "Podcasts d'information / d'animation",
              },
            },
            heading: {
              color: '#ff9800',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '5f5f6c8124c283131f2b92e2',
            i18n: {
              fr: {
                title: `Radios d'entreprise`,
              },
            },
            heading: {
              color: '#e65100',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '611154c5c4055268ad04c520',
            i18n: {
              fr: {
                title: 'Expériences sonores',
              },
            },
            heading: {
              color: '#ff9100',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
