var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.$route.name === 'index' && _vm.$voicer.isOpenSite() === false)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{staticClass:"message_welcome_sir text-alternate",class:{
        'pl-3 pr-3 pt-2': _vm.$voicer.isMobile,
        'pb-2': _vm.$voicer.isMobile && !_vm.$slots['cta'],
        'pa-4': _vm.$voicer.isDesktopOrTablet,
        headline: _vm.$voicer.isDesktopOrTablet,
        'body-2': _vm.$voicer.isMobile,
      }},[_vm._v("\n      Découvrez les programmes conçus et produits par Mediameeting.\n    ")])],1):_vm._e(),_vm._v(" "),_c('w-block',{attrs:{"name":"cta"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }